// @flow
import { compose, withReducer, withHandlers } from "recompose";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import React, {type Node, useEffect, useState} from "react";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import S from "sanctuary";
import {
  setSessionStorage,
  getSessionStorage,
  parseJSON
} from "../../helpers";

import View from "../View";
import {
  METABASE_LOCATION_FILTER_PARAM,
  SYSTEM_VIEW_TYPE,
  ALL_LOCATIONS_NAME
} from "../../helpers/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const defaultLocationOptionsFromView = view => {
  if (!view || view.type !== SYSTEM_VIEW_TYPE) return [];

  const locationFilter = view.filters.find(
    ({ property }) => property === METABASE_LOCATION_FILTER_PARAM
  );

  return locationFilter.options;
};

const initialState = ({ views }) => {
  const viewState = S.pipe(
    [S.map(parseJSON), S.chain(S.eitherToMaybe), S.fromMaybe({})],
    //getSessionStorage("viewState") Ignore view state load for now to ensure that the locations are always loaded
  );

  const view = viewState.selectedView || (views && views[0]);
  const locations =
    viewState.selectedLocations || defaultLocationOptionsFromView(view);
  const tab =
    viewState.selectedTab || view.resources.find(({ index }) => index === 0);

  return {
    selectedView: view,
    selectedLocations: locations.sort((a, b) => a.key.localeCompare(b.key)),
    selectedTab: tab
  };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "REMOVE_LOCATION": {
      const {
        payload: { key }
      } = action;

      const filteredSelectedLocations = S.filter(l => l.key !== key, [
        ...state.selectedLocations
      ]);

      const updatedSelectedLocations =
        filteredSelectedLocations.length === 0
          ? defaultLocationOptionsFromView(state.selectedView)
          : filteredSelectedLocations;

      return { ...state, selectedLocations: updatedSelectedLocations };
    }
    case "SELECT_VIEW": {
      const {
        payload: { selectedLocations, selectedView, selectedTab }
      } = action;

      return { ...state, selectedView, selectedLocations, selectedTab };
    }
    default:
      return state;
  }
};

const filterLocations = (view, locations) => {
  const selectedIds = locations.map(option => option.value);
  const locationFilter = view.filters.find(
    ({ property }) => property === METABASE_LOCATION_FILTER_PARAM
  );

  return locationFilter.options.filter(l =>
    selectedIds.includes(l.value)
  ).sort((a, b) => a.key.localeCompare(b.key));
};

export const withOptions = compose(
  withReducer("state", "dispatch", reducer, initialState),
  withHandlers({
    selectTab: ({
      dispatch,
      state: { selectedView, selectedLocations }
    }) => tab => {
      dispatch({
        type: "SELECT_VIEW",
        payload: {
          selectedView,
          selectedLocations,
          selectedTab: tab
        }
      });
    },
    removeLocation: ({ dispatch }) => key => () => {
      dispatch({
        type: "REMOVE_LOCATION",
        payload: {
          key
        }
      });
    },
    selectLocations: ({
      dispatch,
      state: { selectedView, selectedTab }
    }) => locations => {
      dispatch({
        type: "SELECT_VIEW",
        payload: {
          selectedView,
          selectedLocations: filterLocations(selectedView, locations),
          selectedTab
        }
      });
    },
    selectView: ({ dispatch, views, state: { selectedTab } }) => event => {
      const view = views.find(({ name }) => name === event.target.value);
      const locations = defaultLocationOptionsFromView(view);
      const defaultTab = view.resources.find(({ index }) => index === 0);

      dispatch({
        type: "SELECT_VIEW",
        payload: {
          selectedView: view,
          selectedLocations: locations,
          selectedTab: defaultTab
        }
      });
    }
  })
);

const ViewSelect = ({ views, selectedView: { name }, selectView }) => {
  // Check if "All Locations" exists in the views array
  const allLocationsView = views.find(view => view.name === ALL_LOCATIONS_NAME);

  const filteredViews = views.filter(view => view.name !== ALL_LOCATIONS_NAME);

  return (
    <FormControl>
      <InputLabel>Dashboards</InputLabel>
      <Select value={name} onChange={selectView}>
        {allLocationsView && (
          <MenuItem value={ALL_LOCATIONS_NAME} key="0">
            {ALL_LOCATIONS_NAME}
          </MenuItem>
        )}
        {filteredViews.map(({ name: viewName }, index) => (
          <MenuItem value={viewName} key={index + 1}>
            {viewName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const LocationSelect = ({
  selectedLocations,
  selectedView,
  selectLocations,
  options
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [locations, setLocations] = useState(selectedLocations);

  useEffect(() => {
    setLocations(selectedLocations);
  }, [selectedLocations]);

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
    selectLocations(locations)
  }

  const handleChange = (event, value) => {
    if (isOpen) {
      return setLocations(filterLocations(selectedView, value));
    }

    selectLocations(value);
  }

  return (
    <Autocomplete
      multiple
      data-testid="autocomplete"
      options={options}
      limitTags={3}
      variant="filled"
      getOptionLabel={option => option.key}
      value={locations}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      disableCloseOnSelect
      renderOption={(option, { selected }) => {
        return (
          <li key={option.value}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.key}
          </li>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          label="Locations"
          placeholder="Select locations"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
      ListboxProps={{ 'data-testid': 'autocomplete-dropdown' }}
    />
  );
};

export const FilterContainer = ({
  views,
  selectView,
  selectLocations,
  selectTab,
  removeLocation,
  state: { selectedLocations, selectedView, selectedTab }
}): Node => {
  setSessionStorage(
    "viewState",
    JSON.stringify({
      selectedLocations,
      selectedView,
      selectedTab
    })
  );

  const { filters, type } = selectedView;

  const locationFilter =
    filters.find(
      ({ property }) => property === METABASE_LOCATION_FILTER_PARAM
    ) || {};

  const locationOptions = locationFilter.options;

  return (
    <Grid container style={{ position: "fixed", padding: "20px 48px 0px" }}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-end"
        style={{ padding: "0px 5px 5px 5px" }}
      >
        <Grid item xs="auto">
          <ViewSelect
            selectView={selectView}
            selectedView={selectedView}
            views={views}
          />
        </Grid>
        {type === SYSTEM_VIEW_TYPE && (
          <Grid item xs>
            <LocationSelect
              selectLocations={selectLocations}
              selectedView={selectedView}
              removeLocation={removeLocation}
              selectedLocations={selectedLocations}
              options={locationOptions}
            />
          </Grid>
        )}
      </Grid>
      <Paper style={{ width: "100%", height: "calc(100vh - 150px)" }}>
        <View
          view={selectedView}
          selectedLocations={selectedLocations}
          selectTab={selectTab}
          selectedTab={selectedTab}
        />
      </Paper>
    </Grid>
  );
};

export default S.pipe(
  [withOptions],
  FilterContainer
);

// @flow
import S from "sanctuary";
import { loaderInit } from "./loader";

export const convertURIParamsToObject = (URIParams: string) => {
  if (!URIParams) return {};
  return URIParams.split("&").reduce((acc, hash) => {
    const [key, val] = hash.split("=");
    return {
      ...acc,
      [key]: decodeURIComponent(val)
    };
  }, {});
};

export const convertObjectToURIParams = (obj: Object) => new URLSearchParams(obj).toString();

export const parseJSON = (data: string) => {
  if (data == null) return S.toEither("Data is null", null);
  return S.encaseEither(S.prop("message"))(JSON.parse)(data);
};

// https://stackoverflow.com/a/38340730
export const removeEmpty = (obj: {}) =>
  Object.keys(obj)
    .filter(k => obj[k] !== null && obj[k] !== undefined) // Remove undef. and null.
    .reduce(
      (newObj, k) =>
        typeof obj[k] === "object"
          ? Object.assign({}, newObj, { [k]: removeEmpty(obj[k]) }) // Recurse.
          : Object.assign({}, newObj, { [k]: obj[k] }), // Copy value.
      {}
    );

export const filterKeys = (obj: {}, keys: string[] = []) => {
  if (keys.length === 0) return obj;

  let filteredObject = {};

  Object.keys(obj).forEach(key => {
    if (keys.includes(key)) filteredObject[key] = obj[key];
  });

  return filteredObject;
};

export function propOr<T>(
  defaultValue: T,
  propertyType: Object,
  property: string,
  item: Object
): T {
  return S.fromMaybe(defaultValue, S.get(S.is(propertyType), property, item));
}

export const filterByProp = (prop: string) => (xs: any[]): any[] =>
  S.filter(S.prop(prop), xs);

export const flatten = (arr: any[]): any[] =>
  arr.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);

export const flatMap = (fn: Function) => (xs: any[]): any[] =>
  flatten(S.map(fn, xs));

export const intersection = (l1: any[]) => (l2: any[]): any[] =>
  S.filter(x => l2.find(y => x === y) != null, l1);

export const isIE11 = () => {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return !(ua.indexOf("MSIE") > -1) && ua.indexOf("Trident/") > -1;
};

export const setSessionStorage = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
  return S.toMaybe(value);
};

export const removeSessionStorage = (key: string) => {
  const value = sessionStorage.getItem(key);
  sessionStorage.removeItem(key);
  return S.toMaybe(value);
};

export const getSessionStorage = (key: string) =>
  S.toMaybe(sessionStorage.getItem(key));

export { loaderInit };

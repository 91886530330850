// @flow
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import S from "sanctuary";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { compose, withState, withHandlers } from "recompose";
import { connect } from "react-redux";
import { push } from "redux-little-router";
import { withStyles } from "@material-ui/core/styles";

import type { Dispatch } from "redux";
import type { LocationAction } from "redux-little-router";
import type { RecomposeHandler, ReduxState, User } from "../../flowtypes";

type Style = {
  root: {},
  header: {},
  logo: {},
  menuButton: {}
};

type Props = {
  authenticated: boolean,
  classes: Style,
  handleClose: RecomposeHandler<Props>,
  handleMenu: RecomposeHandler<Props>,
  anchorEl: null | HTMLButtonElement,
  logout: () => void,
  updateValue: mixed => void,
  user: User,
  loading: boolean,
  error: boolean
};

const styles = {
  root: {
    "min-height": 48,
    "max-height": 48,
    padding: "0px 48px 0px"
  },
  header: {
    flex: 2,
    marginLeft: 16,
    marginTop: "4px",
    fontWeight: "bold"
  },
  logo: {
    height: 30
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
};

const mapStateToProps = (state: ReduxState) => ({});

const mapDispatchToProps = (dispatch: Dispatch<LocationAction>) => ({
  logout: (): void => {
    dispatch(push("/logout"));
  }
});

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withState("anchorEl", "updateValue", null),
  withHandlers(
    ({
      handleClose: props => event => {
        props.updateValue(null);
      },
      handleMenu: props => event => {
        props.updateValue(event.currentTarget);
      }
    }: {
      handleClose: RecomposeHandler<Props>,
      handleMenu: RecomposeHandler<Props>
    })
  )
);

const Header = ({
  classes,
  handleClose,
  handleMenu,
  authenticated,
  user,
  anchorEl,
  logout
}: Props) => {
  const open: boolean = Boolean(anchorEl);
  const userName: string = S.maybe("", S.prop("name"), user);
  const accountType: string = "Standard";

  return (
    <AppBar position="static" color="primary">
      <Toolbar className={classes.root}>
        <div>
          <img
            alt="CancerIQ Logo"
            className={classes.logo}
            src={`//assets.canceriq.com/account_type/${accountType}/analytics.png`}
          />
        </div>
        <Typography
          className={classes.header}
          variant="caption"
          color="inherit"
        />
        {authenticated && (
          <div>
            <Typography
              style={{ display: "inline" }}
              variant="body1"
              color="inherit"
            >
              {userName}
            </Typography>
            <IconButton
              aria-owns={open ? "menu-appbar" : null}
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default S.pipe(
  [withStyles(styles), withRedux, enhance],
  Header
);

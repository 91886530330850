// @flow
import ErrorPage from "./components/ErrorPage";
import Header from "./components/Header";
import LoadingPage from "./components/LoadingPage";
import React, { type Node } from "react";
import S from "sanctuary";
import ViewContainer from "./components/ViewContainer";
import { connect } from "react-redux";
import { loaderInit } from "./helpers";

import type {
  Action,
  AnalyticsView,
  ReduxState,
  User,
} from "./flowtypes";
import UnauthorizedPage from "./components/UnauthorizedPage";

type Props = {
  views: AnalyticsView[],
  authenticated: boolean,
  user: User,
  error: boolean,
  fetchUser: () => void,
  location: any
};

const mapStateToProps = (state: ReduxState) => ({
  views: state.app.views,
  selectedView: state.app.selectedView,
  authenticated: state.app.authenticated,
  authorized: state.app.authorized,
  user: state.app.user,
  error: state.app.error,
  location: state.router
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  fetchUser: _ => {
    dispatch({ type: "USER_FETCH_REQUESTED" });
  }
});

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

const App = ({
  views,
  authenticated,
  authorized,
  user,
  fetchUser,
  error,
  selectedView
}: Props): Node => {
  if (S.isNothing(user)) fetchUser();

  const userLoader = loaderInit({
    loading: S.isNothing(user),
    error: error,
    data: { views, authenticated, user, authorized },
  });

  return userLoader.cata({
    Success: ({ views, user, authenticated, authorized }) =>
      authorized
        ?
        <div>
          <Header user={user} authenticated={authenticated} />
          <ViewContainer views={views} selectedView={selectedView} />
        </div>
        : <UnauthorizedPage />,
    Failure: e => <ErrorPage />,
    Loading: () => <LoadingPage flashDelay={0} />
  });
};

export default S.pipe(
  [withRedux],
  App
);

// @flow
import React, { type Node } from "react";
import S from "sanctuary";
import { connect } from "react-redux";
import { graphql } from "react-apollo";

import FilterContainer from "../FilterContainer";
import LoadingPage from "../LoadingPage";
import { VIEWS } from "../../graphql/queries";
import { loaderInit } from "../../helpers";

import type { AnalyticsView, User } from "../../flowtypes";

type Props = {
  authenticated: boolean,
  error: boolean,
  loading: boolean,
  user: User,
  views: AnalyticsView[]
};

const withApollo = graphql(VIEWS, {
  options: ({ user }: { user: User }) => {
    return { variables: {} };
  }
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  sendError: details => dispatch({ type: "ERROR", payload: { details } })
});

const withRedux = connect(
  null,
  mapDispatchToProps
);

const ViewContainer = ({
  data: { loading, error, systemAnalyticsViews },
  sendError
}: Props): Node => {
  const viewsLoader = loaderInit({
    loading: loading,
    error: error,
    data: systemAnalyticsViews
  });

  return (
    <div>
      {viewsLoader.cata({
        Success: views => {
          return <FilterContainer views={views} />;
        },
        Failure: e => {
          sendError({
            component: "ViewContainer",
            reason: e
          });

          return <div />;
        },

        Loading: () => <LoadingPage flashDelay={300} />
      })}
    </div>
  );
};

export default S.pipe(
  [withApollo, withRedux],
  ViewContainer
);

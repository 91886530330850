// @flow
import { taggedSum } from "daggy";

type Props = {
  loading: boolean,
  error: boolean,
  data: mixed
};

export const loaderInit = ({ loading, error, data }: Props) => {
  const Loader = taggedSum("Loader", {
    Success: ["items"],
    Failure: ["error"],
    Loading: []
  });

  Loader.prototype.map = function(f) {
    return this.cata({
      Success: x => Loader.Success(f(x)),
      Failure: () => this,
      Loading: () => this
    });
  };

  return (
    (error ? Loader.Failure("Error In Request") : false) ||
    (loading ? Loader.Loading : false) ||
    Loader.Success(data)
  );
};

// @flow
import React from "react";
import S from "sanctuary";
import SystemView from "./SystemView";
import VendorView from "./VendorView";
import { SYSTEM_VIEW_TYPE, VENDOR_VIEW_TYPE } from "../../helpers/constants";
import { connect } from "react-redux";

import type { Action, AnalyticsView } from "../../flowtypes";

type Props = {
  view: AnalyticsView,
  selectedLocations: any[],
  sendError: ({}) => void
};

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  sendError: details => dispatch({ type: "ERROR", payload: { details } })
});

const withRedux = connect(
  null,
  mapDispatchToProps
);

const View = ({
  view,
  selectedLocations,
  sendError,
  selectedTab,
  selectTab
}: Props) => {
  switch (view.type) {
    case VENDOR_VIEW_TYPE:
      return (
        <VendorView
          view={view}
          selectedTab={selectedTab}
          selectTab={selectTab}
        />
      );
    case SYSTEM_VIEW_TYPE:
      return (
        <SystemView
          view={view}
          selectedLocations={selectedLocations}
          selectTab={selectTab}
          selectedTab={selectedTab}
        />
      );
    default:
      sendError({
        component: "View",
        reason: `VIEW TYPE: ${view.type} is invalid`
      });

      return <div />;
  }
};

export default S.pipe(
  [withRedux],
  View
);

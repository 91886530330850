// @flow

type Routes = {
  [string]: {
    title: string
  }
};

const routes: Routes = {
  "/users/auth/jwt/callback": {
    title: "CancerIQ - Analytics Login"
  },
  "/": {
    title: "CancerIQ - Analytics"
  },
  "/logout": {
    title: "CancerIQ - Analytics"
  }
};

export default routes;

import gql from "graphql-tag";

export const VIEWS = gql`
  query Views {
    systemAnalyticsViews {
      name
      type
      filters {
        type
        property
        options {
          key
          value
        }
      }
      resources {
        resourceUri
        title
        shortTitle
        index
      }
    }
  }
`;

export const GENERATE_ANALYTICS_VENDOR_VIEW = gql`
  query generateAnalyticsVendorView($name: String!) {
    generateAnalyticsVendorView(name: $name) {
      name
      type
      filters {
        type
        property
        options {
          key
          value
        }
      }
      resources {
        index
        title
        resourceUri
        shortTitle
      }
    }
  }
`;

export const GENERATE_ANALYTICS_SYSTEM_VIEW = gql`
  query generateAnalyticsSystemView(
    $locations: [AnalyticsLocationInput!]!
    $name: String!
  ) {
    generateAnalyticsSystemView(locations: $locations, name: $name) {
      name
      type
      filters {
        type
        property
        options {
          key
          value
        }
      }
      resources {
        index
        title
        resourceUri
        shortTitle
      }
    }
  }
`;

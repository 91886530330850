// @flow
import createSagaMiddleware from "redux-saga";
import reducers from "../reducers";
import routes from "../routes";
import { PRODUCTION } from "../helpers/constants";
import { combineReducers, compose, createStore, applyMiddleware } from "redux";
import {
  initializeCurrentLocation,
  routerForBrowser
} from "redux-little-router";
import { userSaga } from "../sagas";

const initialState = window.__INITIAL_STATE || {};

const { reducer, middleware, enhancer } = routerForBrowser({ routes });

const sagaMiddleware = createSagaMiddleware();

const devTools =
  PRODUCTION || window.__REDUX_DEVTOOLS_EXTENSION__ === undefined
    ? f => f
    : window.__REDUX_DEVTOOLS_EXTENSION__();

const store = createStore(
  combineReducers({ router: reducer, app: reducers }),
  initialState,
  compose(
    enhancer,
    applyMiddleware(middleware, sagaMiddleware),
    devTools
  )
);

sagaMiddleware.run(userSaga);

const initialLocation = store.getState().router;

if (initialLocation) {
  store.dispatch(initializeCurrentLocation(initialLocation));
}

export default store;

// @flow
import React from "react";
import S from "sanctuary";
import { connect } from "react-redux";
import { graphql } from "react-apollo";

import LoadingPage from "../../LoadingPage";
import ViewDisplay from "../ViewDisplay";
import { GENERATE_ANALYTICS_SYSTEM_VIEW } from "../../../graphql/queries";
import { loaderInit } from "../../../helpers";

const withApollo = graphql(GENERATE_ANALYTICS_SYSTEM_VIEW, {
  options: ({ view: { name }, selectedLocations }) => {
    const locations = selectedLocations.map(({ key, value }) => ({
      id: value,
      shortName: key
    }));
    return {
      variables: { name, locations }
    };
  }
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  sendError: details => dispatch({ type: "ERROR", payload: { details } })
});

const withRedux = connect(
  null,
  mapDispatchToProps
);

const SystemView = ({
  data: { loading, error, generateAnalyticsSystemView },
  sendError,
  selectedLocations,
  selectedTab,
  selectTab
}) => {
  const viewLoader = loaderInit({
    loading: loading,
    error: error,
    data: {
      view: generateAnalyticsSystemView,
      selectedTab,
      selectTab
    }
  });

  return viewLoader.cata({
    Success: ({ view, selectedTab, selectTab }) => {
      const tab = view.resources.find(
        ({ index }) => index === selectedTab.index
      );

      return (
        <ViewDisplay
          view={view}
          selectedTab={tab}
          selectTab={selectTab}
          selectedLocations={selectedLocations}
        />
      );
    },
    Failure: e => {
      sendError({
        component: "VendorView",
        reason: e
      });

      return <div />;
    },
    Loading: () => <LoadingPage flashDelay={0} />
  });
};

export default S.pipe(
  [withApollo, withRedux],
  SystemView
);

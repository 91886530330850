// @flow
import "./index.css";
import "react-app-polyfill/ie11";

import App from "./App.jsx";
import React from "react";
import ReactDOM from "react-dom";
import client from "./links";
import { unregister } from "./serviceWorker";
import store from "./store";
import { ApolloProvider } from "react-apollo";
import { Provider as ReduxProvider } from "react-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { PENDO_API_KEY, PENDO_ENABLED } from "./helpers/constants";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#6b6d88"
    },
    secondary: {
      main: "#0379c5"
    }
  },
  typography: {
    fontFamily: "DINNextLTPro-Regular, Helvetica, Arial, sans-serif"
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <script src="http://metabase.canceriq.com/app/iframeResizer.js" />
    <CssBaseline />
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ReduxProvider>
  </MuiThemeProvider>,
  document.getElementById("root")
);

unregister();

if (PENDO_ENABLED) {
  if (PENDO_API_KEY) {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"](
                [m].concat([].slice.call(arguments, 0))
              );
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://cdn.pendo.io/agent/static/" + PENDO_API_KEY + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");
  } else {
    console.error("Pendo API Key is not set");
  }
}

// @flow

export const PRODUCTION = process.env.NODE_ENV === "production";
export const TEST = process.env.NODE_ENV === "test";
export const QUERY_SERVICE_API_URL = process.env.REACT_APP_QUERY_SERVICE_API_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const LOGOUT_URL = process.env.REACT_APP_LOGOUT_URL;
export const FETCH_USER_URL = process.env.REACT_APP_FETCH_USER_URL;
export const METABASE_LOCATION_FILTER_PARAM = "locationids";
// enum AnalyticsViewType
export const SYSTEM_VIEW_TYPE = "SYSTEM";
export const VENDOR_VIEW_TYPE = "VENDOR";
export const PENDO_API_KEY = process.env.REACT_APP_PENDO_API_KEY;
export const PENDO_ENABLED = process.env.REACT_APP_PENDO_ENABLED === "true";
export const ALL_LOCATIONS_NAME = "All Locations";

// @flow
import S from "sanctuary";
import {
  LOGIN_URL,
  LOGOUT_URL, PENDO_ENABLED,
} from "../helpers/constants";

import type { AppState, Action } from "../flowtypes";
import { goto } from "../helpers/routing";

// NOTE: Side effect -- navigation
export const redirectToLogin = () => {
  const url = new URL(LOGIN_URL);
  const connection = new URLSearchParams(window.location.search).get('connection');

  url.searchParams.set('returnTo', window.location.href);

  if (connection) {
    url.searchParams.set('connection', connection);
  }

  goto(url.href);
};

// NOTE: Side effect -- storage and navigation
const logout = (): void => {
  const url = new URL(LOGOUT_URL);

  url.searchParams.set('returnTo', window.location.origin);

  goto(url.href);
};

// Update state based on route
const stateOnRoute = (state: AppState, action: Action) => {
  const route = S.fromMaybe(
    "/",
    S.gets(S.is(String), ["payload", "route"], action)
  );

  switch (route) {
    // TODO: Remove this route and just use the logout action.
    // The only place this route is used is the logout button,
    // so it can be removed and the logout action can be used
    // on the button click.
    case "/logout":
      logout();

      // Technically this doesn't need to be here because
      // window.location.assign will navigate away from
      // the app immediately, but I don't want to change
      // the code drastically at this point so I can
      // get this working. After we can confirm it's working,
      // we can remove the state on route completely because
      // it's not necessary.
      return Object.assign({}, state, {
        authorized: false,
        authenticated: false,
        user: S.Nothing
      });

    default:
      return Object.assign({}, state, {});
  }
};

const initialState: AppState = {
  authenticated: false,
  authorized: false,
  user: S.Nothing,
  error: false,
  views: [],
  pendoInitialized: false,
};

// Application reducer -- split into files if it grows
const app = (state: AppState = initialState, action: Action) => {
  switch (action.type) {
    case "ROUTER_LOCATION_CHANGED":
      return stateOnRoute(state, action);
    case "USER_FETCH_SUCCEEDED":
      const user = S.gets(S.is(Object), ["payload", "user"], action);
      const authenticated = S.isJust(user);
      const authorized = user.value?.app_metadata?.analytics_enabled || false;
      const updates = Object.assign({}, state, {
        authenticated: authenticated,
        authorized: authorized,
        user: user,
      });

      if (state.pendoInitialized || !(PENDO_ENABLED && authenticated && authorized)) {
        return updates;
      }

      if (!window.pendo) {
        console.warn("Pendo not found. Make sure the Pendo snippet is included in the page.");
        return updates;
      }

      window.pendo.initialize({
        visitor: {
          id: user.value?.app_metadata?.ciq_id || user.value?.user_id,
          email: user.value?.email,
          role: user.value?.app_metadata?.role,
        },
        account: {
          id: user.value?.app_metadata?.account_id,
          role: user.value?.app_metadata?.account_role,
        },
        excludeAllText: false,
        disableGuides: true
      });

      updates.pendoInitialized = true;

      return updates;
    case "UNAUTHORIZED_USER_FETCH":
      redirectToLogin()

      return Object.assign({}, state, {});
    case "ERROR":
      console.error("Redox error", state, action.payload);

      return Object.assign({}, state, { error: true });
    default:
      return state;
  }
};

export { app as default, stateOnRoute, logout };

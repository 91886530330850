// @flow
import React from "react";

type State = {
  hide: boolean
};

type Props = {
  flashDelay: number // time before loading animation is displayed (ms)
};

class LoadingPage extends React.Component<Props, State> {
  state = { hide: true };

  componentWillMount() {
    const { flashDelay } = this.props;
    var that = this;

    setTimeout(function() {
      that.show();
    }, flashDelay);
  }

  show() {
    if (this.refs.root) {
      this.setState({ hide: false });
    }
  }

  render() {
    const { hide } = this.state;

    return (
      <div ref="root" className="loader" hidden={hide}>
        Loading...
      </div>
    );
  }
}

export default LoadingPage;

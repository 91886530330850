import Typography from "@material-ui/core/Typography";

const UnauthorizedPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <img
        alt="CancerIQ Logo"
        style={{ height: 64, marginBottom: 32 }}
        src="//assets.canceriq.com/account_type/Standard/standard.png"
      />
      <Typography
        variant="h5"
        style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 16 }}
      >
        You're unauthorized to access this page.
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 16 }}
      >
        If this is a mistake, please reach out to us at{" "}
        <a
          style={{ color: "#6b6d88", textDecoration: "none" }}
          href="mailto:support@canceriq.com"
        >
          support@canceriq.com
        </a>
      </Typography>
    </div>
  );
};

export default UnauthorizedPage;
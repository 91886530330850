// @flow
import React from "react";
import S from "sanctuary";
import { connect } from "react-redux";
import { graphql } from "react-apollo";

import LoadingPage from "../../LoadingPage";
import ViewDisplay from "../ViewDisplay";
import { GENERATE_ANALYTICS_VENDOR_VIEW } from "../../../graphql/queries";
import { loaderInit } from "../../../helpers";

const withApollo = graphql(GENERATE_ANALYTICS_VENDOR_VIEW, {
  options: ({ view: { name } }) => {
    return {
      variables: { name }
    };
  }
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  sendError: details => dispatch({ type: "ERROR", payload: { details } })
});

const withRedux = connect(
  null,
  mapDispatchToProps
);

const VendorView = ({
  data: { loading, error, generateAnalyticsVendorView },
  sendError,
  selectedTab,
  selectTab
}) => {
  const viewLoader = loaderInit({
    loading: loading,
    error: error,
    data: {
      view: generateAnalyticsVendorView,
      selectedTab,
      selectTab
    }
  });

  return viewLoader.cata({
    Success: ({ view, selectedTab, selectTab }) => (
      <ViewDisplay
        view={view}
        selectedTab={selectedTab}
        selectTab={selectTab}
      />
    ),
    Failure: e => {
      sendError({
        component: "VendorView",
        reason: e
      });

      return <div />;
    },
    Loading: () => <LoadingPage flashDelay={0} />
  });
};

export default S.pipe(
  [withApollo, withRedux],
  VendorView
);

import { push } from "redux-little-router";
import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_USER_URL } from "../helpers/constants";

export const fetchUserAPI = async _ => {
  const response = await fetch(FETCH_USER_URL, { credentials: "include" });

  if ([200, 401].includes(response.status)) {
    return { user: await response.json(), status: response.status };
  }

  throw new Error("Failed to fetch user info");
};

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
export function* fetchUser() {
  try {
    const { user, status } = yield call(fetchUserAPI);
    if (status === 200) {
      yield put({ type: "USER_FETCH_SUCCEEDED", payload: { user } });
      yield put(push("/"));
    } else {
      yield put({ type: "UNAUTHORIZED_USER_FETCH" });
    }
  } catch (e) {
    yield put({ type: "ERROR", payload: { message: e.message } });
  }
}

export function* userSaga() {
  yield takeLatest("USER_FETCH_REQUESTED", fetchUser);
}

export default userSaga;

// @flow
import React from "react";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const ViewDisplay = ({ view, selectedTab, selectTab, selectedLocations }) => {
  const { resources } = view;
  // Copy array so as not to mutate prop
  const sortedResources = [...resources].sort((a, b) => a.index - b.index);

  return (
    <div>
      <Paper square style={{ flexGrow: 1 }}>
        <Tabs value={selectedTab.index} indicatorColor="primary" variant="fullWidth">
          {sortedResources.map(resource => {
            const { index, shortTitle } = resource;
            return (
              <Tab
                value={index}
                label={shortTitle}
                onClick={() => selectTab(resource)}
                key={index}
              />
            );
          })}
        </Tabs>
      </Paper>
      <iframe
        title={selectedTab.title}
        style={{
          width: "100%",
          height: "calc(100vh - 200px)",
          border: 0,
          paddingTop: "10px"
        }}
        src={selectedTab.resourceUri}
      />
    </div>
  );
};

export default ViewDisplay;

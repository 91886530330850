// @flow
import Button from "@material-ui/core/Button";
import React, { type Node } from "react";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { push } from "redux-little-router";

import type { Dispatch } from "redux";
import type { LocationAction } from "redux-little-router";
import type { ReduxState } from "../../flowtypes";

const mapStateToProps = (state: ReduxState) => ({
  authenticated: state.app.authenticated,
  state: state
});

const mapDispatchToProps = (dispatch: Dispatch<LocationAction>) => ({
  logout: (): void => {
    dispatch(push("/logout"));
  }
});

const withRedux = connect(
  mapStateToProps,
  mapDispatchToProps
);

type Props = {
  authenticated: boolean,
  logout: () => void,
  state: ReduxState
};

const ErrorPage = ({
  authenticated,
  logout,
  state,
  reportError
}: Props): Node => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <img
        alt="CancerIQ Logo"
        style={{ height: 64, marginBottom: 32 }}
        src="//assets.canceriq.com/account_type/Standard/standard.png"
      />
      <Typography
        variant="h5"
        style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 16 }}
      >
        It appears you've encountered an error.
      </Typography>
      <Typography
        variant="subtitle1"
        style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 16 }}
      >
        An error report is on its way to us.
      </Typography>
      <Typography
        variant="caption"
        style={{ marginLeft: "auto", marginRight: "auto", marginBottom: 64 }}
      >
        We're here to help! Please contact us at{" "}
        <a
          style={{ color: "#6b6d88", textDecoration: "none" }}
          href="mailto:support@canceriq.com?subject=Analytics: Error Report&body=I'm having trouble with Analytics, would you mind taking a look?"
        >
          support@canceriq.com
        </a>{" "}
        if signing out and back in does not resolve the issue.
      </Typography>
      <Button onClick={logout} color="primary" variant="contained">
        Sign out
      </Button>
    </div>
  );
};

export default withRedux(ErrorPage);
